import React, { Component } from 'react';
import {Row,Col,Button} from 'react-bootstrap';
import Add from '../Components/Maths/Add';
import Sub from '../Components/Maths/Sub';
import Mult from '../Components/Maths/Mult';
import withRouter from '../Components/WithRouter';
import anime from 'animejs/lib/anime.es.js';
import { location } from '../Components/Maths/common/mutils';
import ReactDOM from 'react-dom/client';

import correct from '../images/correct.png';
import wrong from '../images/wrong.png';


import './practice.css';

import AnimModal from '../Components/Maths/AnimModal';
import { oConstants } from '../Components/Maths/oconstant';
import { playEffect } from '../Components/Maths/common/mSound';
import { timeline } from 'animejs';
import ResultModal from '../Components/Maths/ResultModal';

class MathPractice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initState:false,
            animshow: false,
            resultshow: false,
            counter: 0,
            seed:0,
            width:0,
            height:0,
        };
        this.mgrade = 0;
        this.totalQ = 4;
        this.counterQ = 0;
        this.setQ=this.gen4Qs();
        this.score =0; 
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        if(!this.initState) {
            oConstants.result = {}
            var winWidth = window.innerWidth;
            if(winWidth> 0) { 
                this.initState = true;
                var mcqDiv = document.getElementById("MCQDiv");
                
                const mcQ = ReactDOM.createRoot(
                    mcqDiv
                );
                var nQs = this.buildPractice();
                mcQ.render(nQs);
                this.counterQ++
                
                setTimeout(() => this.setIFocus(),200);
                
            }
        }

    }

    updateWindowDimensions= () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    getQ(grade,mkey) {
        switch(grade){
            case 12:
                return <Sub mAnim={this.showDemo} className="question"
                         key={mkey} numrows="2" numdigit="1" />
            case 21:
                return <Add mAnim={this.showDemo} className="question"
                            key={mkey} numrows="2" numdigit="2" />
            case 22:
                return <Sub mAnim={this.showDemo} className="question"
                    key={mkey} numrows="2" numdigit="2" />
            case 23:
                return <Mult mAnim={this.showDemo} className="question" muldigit="1"
                    key={mkey} numrows="2" numdigit="1" />
            case 31:
                return <Add mAnim={this.showDemo} className="question"
                    key={mkey} numrows="3" numdigit="3" />
            case 32:
                return <Sub mAnim={this.showDemo} className="question"
                        key={mkey} numrows="2" numdigit="3" />
            case 33:
                return <Mult mAnim={this.showDemo} className="question" muldigit="2"
                        key={mkey} numrows="2" numdigit="2" />
            default: 
                return <Add mAnim={this.showDemo} className="question"
                            key={mkey} numrows="2" numdigit="1" />
        }

    }

    gen4Qs() {
        
        var mcq = []
        var mkey = Math.floor(Math.random()*100)
        let {grade} = this.props.params;
        grade = parseInt(grade)
        this.mgrade =  grade;
        
        for(var i =0;i < 4;i++) {
            ++mkey
            var q = this.buildQ(mkey,grade)
            mcq.push(q)
        }     
        return mcq;
    }

    buildQ(mkey,grade) {
        var comQ = this.getQ(grade,mkey); 
        return( <div key={mkey} className={"numBlock m"+ mkey}>
             {comQ}   
        </div>);
    }

    buildPractice(){
        let {grade} = this.props.params;
        let sgrade = grade.toString()

        let digit = 1;
        if(grade == 11 || grade == 23) {
            digit = 2
        }
        if(grade == 21  || grade == 22) {
            digit = 3
        }
        if(grade == 31 || grade == 33 || grade == 32) {
            digit = 4
        }
        grade = parseInt(grade)
        this.mgrade =  grade;
        var numQ = 4;
        
        var md = 3
        var lg = 3
        var sm = 12
        
        if(digit == 2){
            md = 4
            lg = 4
            sm = 12
        }

        if(digit > 2){
            md = 4
            lg = 4
            sm = 12
        }
        if(digit > 3){
            md = 6
            lg = 6
        }

        var blockWidth4 = 360
        var blockWidth3 = 330
        var blockWidth2 = 240
        var blockWidth1 = 200

        var nrow = 1;
        var ncol = numQ/nrow;
        var numQRow = 4;
        var winWidth = window.innerWidth;
        console.log(winWidth)
        if(winWidth >0){
            
            if(digit == 4){
                numQRow = Math.min(Math.floor(winWidth/blockWidth4),4);
            }else if(digit ==3) 
            {
                numQRow = Math.min(Math.floor(winWidth/blockWidth3),4);
 
            } else if(digit ==2) 
            {
                numQRow = Math.min(Math.floor(winWidth/blockWidth2),4);
 
            }else{
                numQRow = Math.min(Math.floor(winWidth/blockWidth1),4);
            }
            nrow = 1;
            ncol = numQRow;
            numQ = ncol 
 
            md = Math.floor(12/ncol)
            lg = Math.floor(12/ncol)
            sm = Math.floor(12/ncol)
        }
  
        var rows =[];
        


        if(numQ < 4){
            this.counterQ = numQ
            var i = (this.totalQ - this.setQ.length)+numQRow;
            i = Math.min(i,4)    
            if(i < 4){
             rows.push(<Button id="nxt" 
                style={{fontFamily:"fantasy",fontSize:"15px"}} 
                onClick={(e) => this.nextQ(e)}>{i} of {this.totalQ} &gt; &gt;
                    </Button>)
            }else{
                rows.push(<Button id="nxt" 
                style={{fontFamily:"fantasy",fontSize:"15px"}} 
                onClick={(e) => this.nextQ(e)}>{i} of {this.totalQ} 
                    </Button>)

            }
        }
      
        var qCount = 0;
        var mkey =0; 
        for(var i=0; i< nrow;i++){
            var cols =[];
            for(var j=0; j< ncol;j++){
                if(qCount == numQ) break;
                mkey++;
                qCount++;
                var compQ = this.setQ.shift(); //this.setQ[qCount-1];
                this.counterQ++;
                var clname = "text-center col-xs-12 col-sm-" +sm+" col-md-" + md +" col-lg-"+lg;
                cols.push(<Col key={mkey} 
                    className={clname}>
                        {compQ}</Col>)
               
            }

            rows.push(<Row key={mkey} className="text-center">
                {cols}
            </Row>);
            rows.push(<br/>)
            if(qCount == numQ) break;
        }     
      
        
        return rows;

    }

    setIFocus = () =>{
        var adivs = document.querySelectorAll('input.Block');
        var mInputs = Array.from(adivs)
        mInputs[0].focus();
        
    } 

    setMyFocus = () =>{
        var adivs = document.querySelectorAll('input.Block');
        var mInputs = Array.from(adivs)
        mInputs.forEach( i => {
            i.style["border-color"] = "red"
            i.focus();
        })
    } 

    nextQ = (e) => {
        oConstants.tried = Object.keys(oConstants.result).length
        console.log(oConstants)
        if(this.setQ.length+oConstants.tried < 4){
             this.setMyFocus()
             return;    
        }

        
        var sButton = document.getElementById("sButton");
        var vButton = document.getElementById("vButton");

        sButton.style["display"] = "block";
        vButton.style["display"] = "block";

        var mcqDiv = document.getElementById("MCQDiv");
                
        const mcQ = ReactDOM.createRoot(
            mcqDiv
        );
        var nQs = this.buildPractice();
        mcQ.render(nQs);
        this.counterQ++

    
    } 

    showDemo = (e) => {
        var {Qs,As} = this.showAnswer(e.target.parentNode.parentNode);
        var num1 = parseInt(Qs[0].textContent);
        var num2 = parseInt(Qs[1].textContent);
        oConstants["num1"] = num1;
        oConstants["num2"] = num2;
        oConstants["mgrade"] = this.mgrade;
        
        this.setState({animshow:true});

    }


    isCorrect = (Qs,As) => {
        var num1T = Qs[0].reduce((a,e) => a + e.textContent,"");
        var num2T = Qs[1].reduce((a,e) => a + e.textContent,"");

        var num1 = parseInt(num1T);
        var num2 = parseInt(num2T);
        
        var strAns = ""

        As.forEach(x => strAns +=x.value)
        var ans = parseInt(strAns);

        if(strAns===''){
            return -1;    
        }

        var rowLen = Object.keys(Qs).length

        var mtype = parseInt(this.mgrade.toString().slice(-1));
        
        var correctAns = num1+num2;

        if(rowLen > 2) {
            var num3T = Qs[2].reduce((a,e) => a + e.textContent,"");
            var num3 = parseInt(num3T);
            correctAns += num3;
        }


        if(mtype == 2) {
            correctAns = num1 -num2
        }else if(mtype == 3) {
            correctAns = num1 * num2
        }

        if(correctAns === ans){
            return 1;
        }
        
        return 0;
    }

    myRows(myComp) {
        
        var divs = myComp.querySelectorAll('div.Block');

        // Convert buttons NodeList to an array
        var tdArr = Array.from(divs);

        var Qs = {};

        var As = [];


        Array.from(divs).forEach(div => {
            var row = div.parentNode.getAttribute("nr");
            if (!(row in Qs)) {
                Qs[row] = []
            }
            Qs[row].push(div);
            
        });

        var adivs = myComp.querySelectorAll('input.Block');

        Array.from(adivs).forEach(div => {
            As.push(div);
        });


        return {"Qs":Qs,"As":As};
    }

    showAnswer(myComp) {
        return (this.myRows(myComp));

    }

    showAnimation = (num1,num2) => {
        oConstants["num1"] = num1;
        oConstants["num2"] = num2;
        oConstants["mtype"] = num2;
        
        this.setState({animshow:true});

    }

    animateAnswer(div) {
        var divs = div.querySelectorAll('div.Block');

       

        var Qs = {};

        var As = [];


        Array.from(divs).forEach(div => {
            var row = div.parentNode.getAttribute("nr");
            if(row in Qs){
                Qs[row].push(div);
            }else{
                Qs[row] = [div];
            }
        });

        var div1 = Qs[0]
        var div2 = Qs[1]

        var num1 = parseInt(div1.map(x => x.textContent).join("")) 
        var num2 = parseInt(div2.map(x => x.textContent).join("")) 
        var num3 = 0 
        
        if(2 in Qs){
            var div3 = Qs[2]
            num3 = parseInt(div3.map(x => x.textContent).join(""))
        }

        // var num3 = parseInt(div1.textContent);
        // var num4 = parseInt(div2.textContent);
            
        var mtype = parseInt(this.mgrade.toString().slice(-1));
        
        var correctAns = num1+num2+num3;
        
        
        if(mtype == 2) {
            correctAns = num1 -num2
        }else if(mtype == 3) {
            correctAns = num1 * num2
        }
        
        var newClass =  correctAns.toString(); 
        var digit0 = newClass.slice(-1);

       
        var inputs = div.querySelectorAll('input');

        var ansLen = inputs.length 

        if(newClass.length < ansLen) {
            var x = ansLen - newClass.length
            newClass = "0".repeat(x) + newClass
        }
        var rem = newClass.slice(0,-1)
        
        var input = inputs[0]
        var counter = ansLen
        if(ansLen > 1) {
            counter = --counter
            input = inputs[counter]
        }
        
        var tl = anime.timeline({
            easing: 'easeInOutCirc',
            autoplay:false,
            complete: (anim) => {
                
            }
        });


        tl = tl.add({
            targets: input,
            duration: 500,
            easing: 'linear',
            value:digit0,
            round: 1,
            opacity: ['25%','50%','100%'],
            offset:0,
            complete : (anime) =>{
                var tables = anime.animatables;
                var d = tables.map(a => a.target);
                var value = d[0].value
                
                d[0].setAttribute("class","Block Color"+value);
                d[0].setAttribute("disabled","true")

            }});
        
        if(ansLen > 1 ){ 
            counter = --counter
            input = inputs[counter]

            var nA = rem.slice(-1);
            rem = rem.slice(0,-1)

            if(!nA){
                nA = "0"
            }

            tl = tl.add({
                targets: input,
                duration: 500,
                easing: 'linear',
                value:nA,
                round: 1,
                opacity: ['25%','50%','100%'],
                offset:500,
                complete : (anime) =>{
                    var tables = anime.animatables;
                    var d = tables.map(a => a.target);
                    var value = d[0].value
                    d[0].setAttribute("class","Block Color"+value);
                    d[0].setAttribute("disabled","true")
                }});
        }

        if(ansLen > 2 ){ 
            counter = --counter
            input = inputs[counter]
            var nA = rem.slice(-1);
            rem = rem.slice(0,-1)

            tl = tl.add({
                targets: input,
                duration: 500,
                easing: 'linear',
                value:nA,
                round: 1,
                opacity: ['25%','50%','100%'],
                offset:1000,
                complete : (anime) =>{
                    var tables = anime.animatables;
                    var d = tables.map(a => a.target);
                    var value = d[0].value
                    d[0].setAttribute("class","Block Color"+value);
                    d[0].setAttribute("disabled","true")
                }});
        }

        if(ansLen > 3 ){ 
            counter = --counter
            input = inputs[counter]
            var nA = rem.slice(-1);
            rem = rem.slice(0,-1)

            tl = tl.add({
                targets: input,
                duration: 500,
                easing: 'linear',
                value:nA,
                round: 1,
                opacity: ['25%','50%','100%'],
                offset:1500,
                complete : (anime) =>{
                    var tables = anime.animatables;
                    var d = tables.map(a => a.target);
                    var value = d[0].value
                    d[0].setAttribute("class","Block Color"+value);
                    d[0].setAttribute("disabled","true")
                }});
        }

        tl.play();
     

    }

    showAnswers = () => {
        var qDIVs = document.getElementsByClassName("numBlock");

        qDIVs = Array.from(qDIVs).filter(div => {
            var style = div.getAttribute("style");
            if(style) {
                return !style.includes("opacity");
            }
            
            return true;
        }); 


        Array.from(qDIVs).forEach( div => {
            this.animateAnswer(div)
            var className = div.getAttribute("class");
            className = className.slice(-3).trim()
            oConstants.result[className] = false;
           
        }) ;
        var sButton = document.getElementById("sButton");
        var vButton = document.getElementById("vButton");

        sButton.style["display"] = "none";
        vButton.style["display"] = "none";

        oConstants.tried = Object.keys(oConstants.result).length
        if(oConstants.tried == 4) {
            setTimeout(() => {
                this.setState({resultshow: true})
            },3000);
        } 
    }


    tryMore = () => {
        this.setState({resultshow:false});
        oConstants.result = {}
        window.location.reload()
        return;
    }

    goBack = () => {
        this.setState({resultshow:false});
        
        return;
    }

    verify = () =>{
  
        var qDIVs = document.getElementsByClassName("numBlock");
        
        qDIVs = Array.from(qDIVs).filter(div => {
            var style = div.getAttribute("style");
            if(style) {
                return !style.includes("opacity");
            }
            
            return true;
        }); 

        var count = 0;
        var prompt =  0;


        var imgMarks = document.getElementsByClassName("mark");

        var imgRight = imgMarks[0].childNodes[0]
        var imgWrong = imgMarks[1].childNodes[0]
       
        
        Array.from(qDIVs).forEach( div => {
            var {Qs,As} = this.myRows(div);
            var isCorrect = this.isCorrect(Qs,As);
            var className = div.getAttribute("class");
            className = className.slice(-3).trim()
            if(isCorrect==1){
                div.style["border-color"] = "green";
                var img = imgRight.cloneNode(true)
                img.style["position"] = "relative"
                img.style["z-index"] = "20"
                img.style["margin-top"] = "-300px"
                
                img.style["width"] = "80px"
                
                div.firstChild.appendChild(img)
                div.style["disabled"] = true
                div.style["opacity"] = "0.6"
                div.style["pointer-events"] = "none"
                oConstants.result[className] = true
                count++;
            }else if(isCorrect==0){
                div.style["border-color"] = "red";
                var img = imgWrong.cloneNode(true)
                img.style["position"] = "relative"
                img.style["z-index"] = "20"
                img.style["margin-top"] = "-300px"
                
                img.style["width"] = "80px"
                
                div.firstChild.appendChild(img)
                div.style["disabled"] = true
                div.style["opacity"] = "0.6"
                div.style["pointer-events"] = "none"
                oConstants.result[className] = false
            }else if(isCorrect==-1){
                prompt++;
            }
        }) ;    

    
        oConstants.tried = Object.keys(oConstants.result).length

        console.log(oConstants)
  
        var correct =0
        if(oConstants.tried) {
            Object.keys(oConstants.result).forEach((k) => {
                if(oConstants.result[k]){
                    correct++;
                }
            })
        }

        var scoreCard = document.getElementsByClassName("scoreCard");
        scoreCard[0].textContent = correct  + " of " + 4;
     
        
        if(oConstants.tried==0){
            playEffect("ifnull")
            this.setMyFocus()
        }else{
        //    if(correct == oConstants.tried){
        //         playEffect("nice")
        //    }     
        }


        if(oConstants.tried == 4) {
            this.setState({resultshow: true})
        }
 
    }

    handleMessageClose = (show) => this.setState({animshow:show});

    animClose = () => this.setState({animshow:false});

    resultClose = () => this.setState({resultshow:false});

    render() {
        var winWidth = window.innerWidth; 

        var myMargin = "10%"
        if(winWidth < 800){
            myMargin = "3%"    
        }

        let {grade} = this.props.params;
        grade = parseInt(grade)
        if(grade == 33){
            myMargin = "3%"
        }

         
        return (<div className="container1" style={{marginLeft:myMargin,marginRight:myMargin,marginTop:"1%"}}> 
                   
                <div id="MCQDiv"/>
                
                <div className="btnDiv biggerText"  
                    style={{marginRight:"10%",fontFamily:"fantasy",
                    fontSize:"22px"}} >
                    
                    <Row className="text-center">
                       
                        <Col className="col-12">
                            <span className="scoreCard Color3"
                                style={{fontSize:"20px",
                                width:"100px",
                                borderRadius:"10px",
                                marginLeft:"100px",
                                border:"2px groove green",
                                float:"left"}}
                                >
                               0/4
                            </span>
                            &nbsp;
                            <Button onClick={this.verify} id="vButton"> 
                                Check
                            </Button>
                            &nbsp;
                            <Button onClick={this.showAnswers} id="sButton" > 
                                Answers
                            </Button>
                           
                        </Col>
                        
                    </Row>
                </div>
                <div className="mark right" style={{width:"1px"}}> 
                    <img style={{width:"100%"}} src={correct}/>
                    </div>
                <div className="mark wrong" style={{width:"1px"}}>
                    <img style={{width:"100%"}} src={wrong}/>
                    </div>
                <AnimModal show={this.state.animshow} 
                        handleClose={this.animClose}  />   
                <ResultModal show={this.state.resultshow} 
                        goBack={this.goBack}
                        handleClose={this.resultClose} tryMore={this.tryMore} />

            </div>);
    }
}
 
export default withRouter(MathPractice);