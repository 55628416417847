import React, { Component } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import {Row,Col} from 'react-bootstrap';
import { Link } from "react-router-dom"; 
import Button from 'react-bootstrap/Button';

class MathToolbar extends Component {
    constructor(props) {
        super(props)
    }
    

    handleClick(e) {
        
        // console.log('key: ', $(event.target).data('key'));
        console.log('key: ', e.target.attributes.getNamedItem('data-key').value);
    }

    render() { 
        return (<div className="container" 
                    style={{fontFamily:"fantasy"}}>
                <br/>

                <Row>
                    <Col md={2}>Grade-I</Col>
                    <Col><ButtonToolbar aria-label="Toolbar with button groups">
                        <ButtonGroup  
                                    size="sm">
                            <Link to="/mathpractice/11">
                                <Button  data-key='11' variant="outline-dark">Add </Button>
                            </Link> &nbsp;
                            <Link to="/mathpractice/12">
                                <Button data-key='12'variant="outline-dark">
                                        Subtract</Button>
                            </Link>
                            </ButtonGroup> 
                        </ButtonToolbar>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={2}>Grade-II</Col>
                    <Col>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup size="sm">
                                <Link to="/mathpractice/21">
                                    <Button variant="outline-dark">Add </Button> &nbsp;
                                </Link>
                                <Link to="/mathpractice/22">
                                    <Button variant="outline-dark">Subtract</Button>&nbsp;
                                </Link>
                                <Link to="/mathpractice/23">
                                    <Button variant="outline-dark">Multiply</Button>
                                </Link>
                                
                            </ButtonGroup> 
                        </ButtonToolbar> 
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col md={2}>Grade-III</Col>
                    <Col>
                        <ButtonToolbar aria-label="Toolbar with button groups">
                            <ButtonGroup size="sm">
                            <Link to="/mathpractice/31">
                                <Button variant="outline-dark">Add </Button> &nbsp;
                            </Link>
                            <Link to="/mathpractice/32">
                                <Button variant="outline-dark">Subtract</Button>&nbsp;
                            </Link>
                            <Link to="/mathpractice/33">
                                <Button variant="outline-dark">Multiply</Button>
                            </Link>
                            </ButtonGroup> 
                        </ButtonToolbar> 
                    </Col>
                </Row>
                <br/>
        </div>
        );
    }
}
 
export default MathToolbar;