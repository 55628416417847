import React, { Component } from 'react';
import {Row,Col, Form} from 'react-bootstrap';
import ABlock from './ABlock'
import './add.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import AnsBlock from './AnsBlock';
import { memo } from "react";

class Add extends Component {
    constructor(props) {
        super(props);
        this.state = {
            digit:this.props.numdigit,
            rows:this.props.numrows,
            initState:0
            
        }; 
        this.myRef = React.createRef();
        this.anRef = React.createRef();
        this.mynumbers = {"q":[],"a":0};
    }


    componentDidMount() {
        if(this.state.initState == 0){
            this.setState((prevState,props) => {
                 prevState.initState = 1   
            })
        }
    }

    myRows(myComp) {
        
        var divs = myComp.querySelectorAll('div.Block');

        // Convert buttons NodeList to an array
        var tdArr = Array.from(divs);

        var Qs = {};

        var As = [];


        Array.from(divs).forEach(div => {
            var row = div.parentNode.getAttribute("nr");
            if(row !== "answer"){
                Qs[row] = div;
            }else{
                As = div;
            }
        });

        var adivs = myComp.querySelectorAll('input.Block');

        Array.from(adivs).forEach(div => {
            var row = div.parentNode.getAttribute("nr");
            As = div;
            
        });

        return {"Qs":Qs,"As":As};
    }


    // setMyNumber(numbers,sum) {
    //     if(this.state.initState == 0) {
    //         this.mynumbers["q"] = numbers;
    //         this.mynumbers["a"] = sum
    //     }
    // }

    prepAnimation(Qs) {
        var num1 = parseInt(Qs[0].textContent);
        var num2 = parseInt(Qs[1].textContent);
          
        this.props.mAnim(num1,num2);

        // var blocks =  mcontainer.querySelectorAll('div.nAB');
        // var img;// =  mcontainer.querySelectorAll('svcg').cloneNode(true);

        // var nB1 = Qs[0].cloneNode(true)
        // var nB2 = Qs[1].cloneNode(true)
        
        // nB1.style["width"] = "20px !important";
        // nB2.style["width"] = "20px !important";
        
        // blocks[0].appendChild(nB1)
        // blocks[1].appendChild(nB2)
        
        // for (var i= 0; i < num1; i++) {
        //     img =  mcontainer.querySelectorAll('svg')[0].cloneNode(true);
        //     blocks[0].appendChild(img)
        // }

        // for (var i= 0; i < num2; i++) {
        //     img =  mcontainer.querySelectorAll('svg')[0].cloneNode(true);
        //     blocks[1].appendChild(img)
        // }
        // mcontainer.style["display"] = "block";

    }

    showAnswer(e) {
        if(this.myRef.current){
            var myComp =  this.myRef.current;
            var {Qs,As} = this.myRows(myComp);
  
            var container = this.anRef.current;

            this.prepAnimation(Qs);                


        }
    }

    getNumber(){
        var mind = Math.floor(Math.random()*10);
        return mind;
    }

    getRandomInt (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getNumbers(num,digit) {
        var maxStr = "9".repeat(digit);
        var max = parseInt(maxStr);
        var min = 0;

        var sum = 0;        
        var mynumbers = [];
        for(var i= 0; i < num; i++){
            var number = this.getRandomInt(min,max);
            sum +=number;
            number = number.toString()
            if(number.length < digit){
                var sub = digit - number.length;
                number = " ".repeat(sub) + number;
            }
            mynumbers.push(number);
        }
        console.log(mynumbers)
        return {"numbers": mynumbers, "sum":sum};


    }

    buildBlock() {
        var rows = []; 
        var count = 0
        var {numrows,numdigit} = this.props;
        numrows = parseInt(numrows)
        numdigit = parseInt(numdigit)
        
        var {numbers,sum} = this.getNumbers(numrows,numdigit);

        // this.setState((prevState,props) =>{
        //     prevState.mynumbers["q"] = numbers;
        //     prevState.mynumbers["a"] = sum;
        // });
        //this.setMyNumber(numbers,sum);
        
        var sumLength = sum.toString().length;

        for(var i=0;i < numrows;i++) {
            var cols= []
            var nums = numbers[i].split("");
            for(var j=0;j < numdigit+1;j++)
            {
                count++;
           
                if(j == 0) {
                    if(i== numrows-1){
                        cols.push(<Col 
                            className="Blank" 
                         key={++count}>
                            +
                        </Col>)
                    }else{
                        cols.push(<Col 
                            className="Blank" 
                         key={++count}>
                            &nbsp;
                        </Col>)
                    }
                
                }else{
                     
                    var number = this.getNumber(); 
                        cols.push(<Col  nr={i} key={++count}>
                            <ABlock className="Block"  name={nums[j-1]}>
                                {nums[j-1]}
                                </ABlock>
                        </Col>)
                }
            }
            rows.push(<Row  className="noselect" nr={i}  key={++count}>{cols}</Row>)

            if(i==numrows-1) {
                rows.push(<div className="total"> </div> )
            }
            
        }

        var cols= []
        for(var j=0;j < numdigit+1;j++)
        {   
            if(j == 0 && sumLength< (numdigit+1)){
                cols.push(<Col   key={++count}>
                    &nbsp;
                </Col>)
            }else{
                cols.push(<Col  key={++count}>
                    <AnsBlock className="Block" name="?"/>
                </Col>)
            }
            
        }
        rows.push(<Row nr="answer" key={++count}>{cols}</Row>)

        return rows;
    }
    
    help() {
        if(this.state.digit ==1){
            return  <FontAwesomeIcon 
                       onClick={(e) => this.props.mAnim(e)}
        
                        icon={faLightbulb} style={{width:"10px"}} />
        }
    }

    render() { 
        return (<>
        {/* c */}
            
        <div ref={this.myRef} className='container'>

           {this.help()} 
           {this.buildBlock()}

        </div>


        </>);
    }
}
 
export default memo(Add);