import React, { Component, memo } from 'react';
import {Row,Col} from 'react-bootstrap';
import ABlock from './ABlock'
import AnsBlock from './AnsBlock';
import './add.css';

class Mult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            digit:this.props.numdigit,
            rows:2,
            muldigit:this.props.muldigit
        } 
    }

    getNumber(){
        var mind = Math.floor(Math.random()*10);
        return mind;
    }

    getRandomInt (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getNumbers(digit) {
        var maxStr = "9".repeat(digit);

        
        var max = parseInt(maxStr);
        var min = 0;

        var mynumbers = [];

        var product = 1;
        
        var number1 = this.getRandomInt(min,max);
        product *= number1;

        var number = number1.toString()
        
        if(number.length < digit){
            var sub = digit - number.length;
            number = " ".repeat(sub) + number;
        }
        mynumbers.push(number);

        var {muldigit} = this.props;
        var muld = parseInt(muldigit);
        var muldStr = "9".repeat(muld);
        var muldMax = parseInt(muldStr);;
        
        var number2 = this.getRandomInt(min,muldMax);
        product *= number2;
        number = number2.toString()
        if(number.length < digit){
            var sub = digit - number.length;
            number = " ".repeat(sub) + number;
        }
        
        mynumbers.push(number);
        if(number1 < number2){
            mynumbers = mynumbers.reverse();
        }

        return {"numbers":mynumbers,"product":product};

    }

    buildBlock() {
        var rows = []; 
        var count = 0
        var {numrows,numdigit} = this.props;
        numrows = 2
        numdigit = parseInt(numdigit)
        
        var {numbers,product} = this.getNumbers(numdigit);

        var pLength = product.toString().length;

        for(var i=0;i < numrows;i++) {
            var cols= []
            var nums = numbers[i].split("");
            for(var j=0;j < numdigit+1;j++)
            {
                count++;
           
                if(j == 0) {
                    if(i== numrows-1){
                        cols.push(<Col 
                            style={{width:"50px",fontSize:"33px",fontFamily:"fantasy"}}
                         key={++count}>
                            x
                        </Col>)
                    }else{
                        cols.push(<Col 
                            style={{width:"50px",fontSize:"33px",fontFamily:"fantasy"}}
                         key={++count}>
                            &nbsp;
                        </Col>)
                    }
                
                }else{
                     
                    cols.push(<Col nr={i} key={++count}>
                        <ABlock className="Block" name={nums[j-1]}>
                            {nums[j-1]}
                            </ABlock>
                    </Col>)
                }
            }
            rows.push(<Row className="noselect" nr={i} key={++count}>{cols}</Row>)

            if(i==numrows-1) {
                rows.push(<div className="total"> </div> )
                    
            }
            
        }

        var cols= []

        var maxLength = Math.max(pLength,numdigit+1)

        for(var j=0;j < maxLength;j++)
        {   
            // if(j ==0){
            //     cols.push(<Col  key={++count}>
            //         &nbsp;
            //     </Col>)
            // }else{
                cols.push(<Col  key={++count}>
                    <AnsBlock className="Block" name="?"/>
                </Col>)
            //}
            
        }
        rows.push(<Row  nr="answer" className="noselect" key={++count}>{cols}</Row>)

        return rows;
    }
    
    render() { 
        return ( <div ref={this.myRef} className='container'>
           {this.buildBlock()}
        </div>);
    }
}
 

export default  memo(Mult);