import React, { Component } from 'react';
import {Row,Col} from 'react-bootstrap';
import '../Components/Maths/add.css';

import MathToolbar from '../Components/MathToolbar';

class NumberGame extends Component {
    state = {  } 
    render() { 
        return ( <div className="container">
                   <MathToolbar/>
             </div>);
    }
}
 
export default NumberGame;