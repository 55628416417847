import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { oConstants } from './oconstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCat } from '@fortawesome/free-solid-svg-icons'
import { config } from "@fortawesome/fontawesome-svg-core";
import ABlock from './ABlock'
import {Row,Col} from 'react-bootstrap';
import anime from 'animejs/lib/anime.es.js';
import { adjustlocation, location, remove, setLocation } from './common/mutils';
import { playEffect } from './common/mSound';


config.autoAddCss = false;


class AnimModal extends Component {
  constructor(props) {
     super(props)
     this.state = {
      close:false,
      show: false
    }
  }
     
    appendMe = () =>{
        var imgs = [];
        var {num1,num2,mgrade} = oConstants;

        var mtype = 0
        var size1 = "20px";
        var size2 = "20px";
        var color1 = "black";
        var color2 = "black";
        
        if(mgrade && mgrade.toString().endsWith("2")){
          mtype = 1;
          color2 = "white";
        }

        var mkey = 0;
        imgs.push(<ABlock key={++mkey} name={num1}/>)
        for(var i=0; i < num1; i++) {
          imgs.push(<FontAwesomeIcon key={++mkey} icon={faCat} 
              style={{width:size2,padding:"2px",color:color2}}/>)
          
        }

        var img2 = [];
        
        img2.push(<ABlock key={++mkey} name={num2}/>)
        for(var i=0; i < num2; i++) {
          img2.push(<FontAwesomeIcon key={++mkey} 
                icon={faCat} style={{width:"20px",padding:"2px",color:color1}} />)
        }
        
        var textContent = "These cats are friends. They want to live as big family. Lets us count how many are there!";
        var sum = num1 + num2;
        
        if(mgrade && mgrade.toString().endsWith("2")){
          textContent = "These cats are friends. But one black cat want to leave with white cat! So let us count how many are left?";
          sum = num1 - num2;
        }

        // res.push(<ABlock key={++mkey} name={sum}/>)
        // for(var i=0; i < sum; i++) {
        //   res.push(<FontAwesomeIcon key={++mkey} 
        //         icon={faCat} style={{width:size2,padding:"2px",color:color2}} />)
        // }
        return {"imgs":imgs,"img2": img2,"text":textContent}

    }

    closeAnimation() {

      this.setState((prevState,props) => {
        prevState.show = false;
      })

    }


    transferAnimation(toDiv,fromDiv) {
      if(fromDiv.childNodes.length == 1){
          setTimeout(() => {
            remove(fromDiv)
            var textContent = toDiv.textContent;
            playEffect("n"+textContent)
            document.getElementById("message").textContent = 
              "Yay! so now there are total of " + textContent + " cats living together!"
          },100);
          return;
      }
      var fromnumBlock = fromDiv.firstChild;
      var tonumBlock = toDiv.firstChild;
      
      var svg = fromDiv.lastChild;
      var lastChild = toDiv.lastChild;
      var toLoc = location(lastChild);
      var fromLoc = location(svg);
      var copy = svg.cloneNode(true);
      document.body.appendChild(copy);
      setLocation(copy,fromLoc)
      copy.style["z-index"] = 10000;
      svg.style["display"] = "none";

      var deltaY = toLoc.Y - fromLoc.Y 
      var deltaX = toLoc.X - fromLoc.X + 20 
      playEffect("move")

      var fromNum = parseInt(fromnumBlock.textContent)  
      var toNum = parseInt(tonumBlock.textContent)  
      
      anime({
        targets: copy,
        duration: 1000,
        easing: 'easeInOutCirc',
        translateY: deltaY,
        translateX: deltaX,
        complete : (anime) =>{
              remove(copy)
              remove(svg);
              
              toDiv.appendChild(svg); 
              svg.style["display"] = "block";
              fromnumBlock.textContent = fromNum -1
              tonumBlock.textContent = toNum +1
              if( (fromNum-1) > 0) {
                  playEffect("n"+(toNum +1))
              }
              this.transferAnimation(toDiv,fromDiv);
               
          }}
        );
    }


    subtractAnimation(toDiv,fromDiv) {
      if(fromDiv.childNodes.length == 1){
          setTimeout(() => {
            remove(fromDiv)
            var textContent = toDiv.textContent;
            playEffect("n"+textContent)

            if(textContent > "0") {
              document.getElementById("message").textContent = 
              "Oh! family is now smaller. Now only " + textContent + " cats are left!"
            }else{
              remove(toDiv)
              document.getElementById("message").textContent = 
              "Oh! No cat or 0 is left in the family. All cats have left!"

            }
          },100);
          return;
      }
      var fromnumBlock = fromDiv.firstChild;
      var tonumBlock = toDiv.firstChild;
      
      var svg1 = fromDiv.lastChild;
      var svg2 = toDiv.lastChild;
      
      var toLoc = location(svg1);
      var fromLoc = location(svg2);
      
      var copy1 = svg1.cloneNode(true);
      document.body.appendChild(copy1);
      setLocation(copy1,toLoc)

      copy1.style["z-index"] = 10000;
      
      var copy2 = svg2.cloneNode(true);
      document.body.appendChild(copy2);
      setLocation(copy2,fromLoc)
      copy2.style["z-index"] = 10000;
      
      remove(svg2);
      remove(svg1);

      var deltaY = -(toLoc.Y - fromLoc.Y)/2 
      var deltaX = -(toLoc.X - fromLoc.X)/2

      var fromNum = parseInt(fromnumBlock.textContent)  
      var toNum = parseInt(tonumBlock.textContent)  
      
      var tl = anime.timeline({
        easing: 'easeInOutCirc',
        autoplay:false,
        complete: (anim) => {
          fromnumBlock.textContent = fromNum -1
          tonumBlock.textContent = toNum -1
    
            anime({
              targets: [copy1,copy2],
              duration: 500,
              easing: 'easeInOutCirc',
              translateX: +200,
              opacity: ["100%","50%","25%","0%"],
              offset:1000,
              complete : (anime) =>{
                    remove(copy2)
                    remove(copy1)
                    this.subtractAnimation(toDiv,fromDiv);
  
                }}
            );
        }
      });

      tl = tl.add({
        targets: copy1,
        duration: 1000,
        easing: 'easeInOutCirc',
        translateY: deltaY,
        translateX: deltaX,
        complete : (anime) =>{
               
          }}
      );

      tl = tl.add({
        targets: copy2,
        duration: 1000,
        easing: 'easeInOutCirc',
        translateY: -deltaY,
        translateX: -deltaX,
        complete : (anime) =>{
               
          }}
        );

         tl.play(); 
    }

    showAnimation(e) {
      var btnText = e.nativeEvent.target.textContent; 

      if(btnText !== "Close"){
    
          var {mgrade} = oConstants;

          e.nativeEvent.target.textContent = "Close";
      
          var animDialog = document.getElementById("animModal");
          var divs = animDialog.querySelectorAll('div.nAB');

          var divA = divs[0].lastChild;
          var divB = divs[1].lastChild;
          var textA = parseInt(divA.textContent);
          var textB = parseInt(divB.textContent);

          if(mgrade && mgrade.toString().endsWith("2")){
              this.subtractAnimation(divA,divB);
          }else{
              if(textA > textB) {
                this.transferAnimation(divA,divB);
              }else{
                this.transferAnimation(divB,divA);
    
              }
          }

            

          
      }
      else{
        this.props.handleClose();
      }
   
  }

    // showAnimation(e) {
        

    //     var btnText = e.nativeEvent.target.textContent; 

    //     if(btnText !== "Close"){
      
    //         var {mgrade} = oConstants;

    //         e.nativeEvent.target.textContent = "Close";
        
    //         var animDialog = document.getElementById("animModal");
    //         var divs = animDialog.querySelectorAll('div.nAB');

    //         var divA = divs[0];
    //         var divB = divs[1];
    //         var resDiv = divs[2];
            

    //         var locA = location(divs[0]);
    //         var locB = location(divs[1]);
    //         var locR = location(divs[2]);

    //         var deltaY = locB.Y - locA.Y 

    //         var deltaY2 = locA.Y - locR.Y + deltaY/2 

    //         // Convert buttons NodeList to an array
    //         //var nABs = Array.from(divs);

    //         playEffect("move")

    //         anime({
    //           targets: divA,
    //           duration: 1000,
    //           easing: 'linear',
    //           opacity: ['100%','50%','25%','0%'],
    //           //class: [sNodeClass,newClass],
    //           translateY: deltaY/2,
    //             complete : (anime) =>{
    //               // this.setState((prevState,props) => {
    //               //     prevState.close = true;
    //               // })
    //             }}
    //           );

    //           anime({
    //             targets: divB,
    //             duration: 1000,
    //             easing: 'linear',
    //             //class: [sNodeClass,newClass],
    //             opacity: ['100%','50%','25%','0%'],
    //             translateY: -deltaY/2,
    //             complete : (anime) =>{

    //             }}
    //           );  

    //           anime({
    //             targets: resDiv,
    //             duration: 500,
    //             easing: 'linear',
    //             //class: [sNodeClass,newClass],
    //             opacity: ['0%','25%','50%','100%'],
    //             translateY: deltaY2,
    //             complete : (anime) =>{

    //             }}
    //           );
    //     }
    //     else{
    //       this.props.handleClose();
    //     }
     
    // }

   
    

    render() { 
        var {show} = this.props;
        var {imgs,img2,text} = this.appendMe();
        return (<Modal  id="animModal" show={this.props.show}
        centered
        size="sm">
          <Modal.Header>
            <Modal.Title> <span className="em"> The total number of cats </span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
                <div className="nAB">
                    <Row>{imgs}</Row>
                </div>
                <div className="nAB">
                <Row>{img2}</Row>
                </div>
                <div className="nAB" id="message">
                    {text}
                </div>
          </Modal.Body>
           <Modal.Footer>
               <Button variant="warning" 
                      onClick={(e) => this.showAnimation(e)}>
                 Check Answer
              </Button>
          </Modal.Footer>
        </Modal>);
    }
}
 
export default AnimModal;