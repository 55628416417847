import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { playEffect } from './common/mSound';
import { oConstants } from './oconstant';
import { Link } from "react-router-dom"; 
import TwitterConvTrkr from "react-twitter-conversion-tracker";

class ResultModal extends Component {
  constructor(props) {
     super(props)
     this.state = {
      close:false,
      show: false
    }
  }
  
  closeAnimation() {

    this.setState((prevState,props) => {
      prevState.show = false;
    })

  }

  tryMore = (e) => {
        this.props.handleClose();
  }

  goBack = (e) => {
     this.props.handleClose();
  }

  playSound = (correct) => {
    if(this.props.show && correct>0){
      switch(correct){
        case 0:
            playEffect("sorry")
            break;
        case 1:
            playEffect("oneof4")
            break;
        case 2:
            playEffect("twoof4")
            break;
        case 3:
            playEffect("threeof4")
            break;
        case 4:
            playEffect("genius")
            break;
      }
    }
  } 

    render() { 


      TwitterConvTrkr.init("od7bm");
       TwitterConvTrkr.pageView();
      
        var {result} =  oConstants.result;
        var correct =0
        if(oConstants.tried) {
            Object.keys(oConstants.result).forEach((k) => {
                if(oConstants.result[k]){
                    correct++;
                }
            })
        }
        this.playSound(correct)
        var message = ""
        var detMessage = ""
        switch(correct) {
            case 0:
               message = "Sorry!"
               detMessage = correct + " of 4";
               break 
            case 1:
              message = "Ok!"
              detMessage = correct + " of 4";
              break 
            case 2:
              message = "Good!"
              detMessage = correct + " of 4";
              break 
            case 3:
              message = "Very good!"
              detMessage = correct + " of 4";
              break 
            case 4:
              message = "Excellent!"
              detMessage = correct + " of 4";
              break 
           
        } 

        return (<Modal  id="resultModal" show={this.props.show}
        centered
        size="sm">
          <Modal.Header>
            <Modal.Title> <span className="em"> Result </span></Modal.Title>
          </Modal.Header>
          <Modal.Body>
                  <h2> {message}  </h2>

                  <h3> {detMessage}  </h3>

          </Modal.Body>
           <Modal.Footer>
               <Button variant="warning" 
                      onClick={() => this.props.tryMore()}>
                 Try More
              </Button>
              <Link to="/math">
                <Button variant="warning" 
                        onClick={() => this.props.goBack()}>
                  Go Back
                </Button>
              </Link>
          </Modal.Footer>
        </Modal>);
    }
}
 
export default ResultModal;