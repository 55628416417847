import oneof4 from '../../massets/1of4.mp3'
import twoof4 from '../../massets/2of4.mp3'
import threeof4 from '../../massets/3of4.mp3'
import genius from '../../massets/genius.mp3'
import einstein from '../../massets/einstein.mp3'

import addsome from '../../massets/addsome.mp3'
import awesome from '../../massets/awesome.mp3'

import dworry from '../../massets/dont_worry.mp3'

import ifnull from '../../massets/ifnull.mp3'
import kt from '../../massets/keep_trying.mp3'
import nice from '../../massets/nice_work.mp3'
import notcorrect from '../../massets/not_correct.mp3'
import sorry from '../../massets/sorry.mp3'
import try_add from '../../massets/try_add.mp3'
import try_sub from '../../massets/try_subtract.mp3'
import wow_all from '../../massets/wow_all.mp3'
import move from '../../massets/roll_down.mp3'

import n1 from '../../massets/1.mp3'
import n2 from '../../massets/2.mp3'
import n3 from '../../massets/3.mp3'
import n4 from '../../massets/4.mp3'
import n5 from '../../massets/5.mp3'
import n6 from '../../massets/6.mp3'
import n7 from '../../massets/7.mp3'
import n8 from '../../massets/8.mp3'
import n9 from '../../massets/9.mp3'
import n10 from '../../massets/10.mp3'
import n11 from '../../massets/11.mp3'
import n12 from '../../massets/12.mp3'
import n13 from '../../massets/13.mp3'
import n14 from '../../massets/14.mp3'
import n15 from '../../massets/15.mp3'
import n16 from '../../massets/16.mp3'
import n17 from '../../massets/17.mp3'
import n18 from '../../massets/18.mp3'
import n19 from '../../massets/19.mp3'
import n20 from '../../massets/20.mp3'
import n0 from '../../massets/0.mp3'

import totalis from '../../massets/totalis.mp3'




import { oConstants } from '../oconstant'


export function matchEffect() {
   const map =  {
    "oneof4":  new Audio(oneof4),
    "twoof4":  new Audio(twoof4),
    "threeof4":  new Audio(threeof4),
    "genius":  new Audio(genius),
    "einstein":  new Audio(einstein),
    "addsome":  new Audio(addsome),
    "awesome": new Audio(awesome),
    "dworry": new Audio(dworry),
    "addsome":  new Audio(addsome),
    "awesome": new Audio(awesome),
    "ifnull": new Audio(ifnull),
    "kt": new Audio(kt),
    "nice": new Audio(nice),
    "not_correct": new Audio(notcorrect),
    "sorry": new Audio(sorry),
    "try_add": new Audio(try_add),
    "try_sub": new Audio(try_sub),
    "wow_all": new Audio(wow_all),
    "move": new Audio(move),
    "n0": new Audio(n0),
    "n1": new Audio(n1),
    "n2": new Audio(n2),
    "n3": new Audio(n3),
    "n4": new Audio(n4),
    "n5": new Audio(n5),
    "n6": new Audio(n6),
    "n7": new Audio(n7),
    "n8": new Audio(n8),
    "n9": new Audio(n9),
    "n10": new Audio(n10),
    "n11": new Audio(n11),
    "n12": new Audio(n12),
    "n13": new Audio(n13),
    "n14": new Audio(n14),
    "n15": new Audio(n15),
    "n16": new Audio(n16),
    "n17": new Audio(n17),
    "n18": new Audio(n18),
    "n19": new Audio(n19),
    "n20": new Audio(n20),
    "totalis": new Audio(totalis),
    
    
    }

    return;
} 

export function playEffect(effectName) {
    var audios = null 
    
    
    if(oConstants.audios == null) {
        audios = {
            "oneof4":  new Audio(oneof4),
            "twoof4":  new Audio(twoof4),
            "threeof4":  new Audio(threeof4),
            "genius":  new Audio(genius),
            "einstein":  new Audio(einstein),
            "addsome":  new Audio(addsome),
            "awesome": new Audio(awesome),
            "dworry": new Audio(dworry),
            "addsome":  new Audio(addsome),
            "awesome": new Audio(awesome),
            "ifnull": new Audio(ifnull),
            "kt": new Audio(kt),
            "nice": new Audio(nice),
            "not_correct": new Audio(notcorrect),
            "sorry": new Audio(sorry),
            "try_add": new Audio(try_add),
            "try_sub": new Audio(try_sub),
            "wow_all": new Audio(wow_all),
            "move": new Audio(move),
            "n0": new Audio(n0),
            "n1": new Audio(n1),
            "n2": new Audio(n2),
            "n3": new Audio(n3),
            "n4": new Audio(n4),
            "n5": new Audio(n5),
            "n6": new Audio(n6),
            "n7": new Audio(n7),
    
            "n8": new Audio(n8),
            "n9": new Audio(n9),
            "n10": new Audio(n10),
            "n11": new Audio(n11),
            "n12": new Audio(n12),
            "n13": new Audio(n13),
            "n14": new Audio(n14),
            "n15": new Audio(n15),
            "n16": new Audio(n16),
            "n17": new Audio(n17),
            "n18": new Audio(n18),
            "n19": new Audio(n19),
            "n20": new Audio(n20),
            "totalis": new Audio(totalis),
    
        }
        oConstants.audios = audios; 
    }
    if(effectName === "init"){
        return;
    }

    if(!(effectName in oConstants.audios)) {
        var map = matchEffect();
        oConstants.audios[effectName] = map[effectName];
        var audio = matchEffect()[effectName];
        audio.play();
    }else{
        var audio = oConstants.audios[effectName];
        
        audio.play();
    }
}

