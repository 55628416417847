import React, { Component } from 'react';
import {Row,Col} from 'react-bootstrap';
import ABlock from './ABlock'
import AnsBlock from './AnsBlock';
import './add.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulb } from '@fortawesome/free-solid-svg-icons'
import { memo } from "react";

class Sub extends Component {
    constructor(props) {
        super(props);
        this.state = {
            digit:this.props.numdigit,
            rows:2,
        } 
    }

    getNumber(){
        var mind = Math.floor(Math.random()*10);
        return mind;
    }

    getRandomInt (min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    getNumbers(num,digit) {
        var maxStr = "9".repeat(digit);
        var max = parseInt(maxStr);
        var min = 0;

        var mynumbers = [];
        for(var i= 0; i < num; i++){
            var number = this.getRandomInt(min,max);
            max = number;
            number = number.toString()
            if(number.length < digit){
                var sub = digit - number.length;
                number = " ".repeat(sub) + number;
            }
            mynumbers.push(number);
        }
        console.log(mynumbers)
        return mynumbers;



    }

    buildBlock() {
        var rows = []; 
        var count = 0
        var {numrows,numdigit} = this.props;
        numrows = 2
        numdigit = parseInt(numdigit)
        
        var numbers = this.getNumbers(numrows,numdigit);

        for(var i=0;i < numrows;i++) {
            var cols= []
            var nums = numbers[i].split("");
            for(var j=0;j < numdigit+1;j++)
            {
                count++;
           
                if(j == 0) {
                    if(i== numrows-1){
                        cols.push(<Col 
                            style={{width:"50px",fontSize:"33px",fontFamily:"fantasy"}}
                         key={++count}>
                            -
                        </Col>)
                    }else{
                        cols.push(<Col 
                            style={{width:"50px",fontSize:"33px",fontFamily:"fantasy"}}
                         key={++count}>
                            &nbsp;
                        </Col>)
                    }
                
                }else{
                     
                    var number = this.getNumber(); 
                        cols.push(<Col nr={i} key={++count}>
                            <ABlock className="Block" name={nums[j-1]}>
                                {nums[j-1]}
                                </ABlock>
                        </Col>)
                }
            }
            rows.push(<Row className="noselect" nr={i}  key={++count}>{cols}</Row>)

            if(i==numrows-1) {
                rows.push(<div className="total"> </div> )
                    
            }
            
        }

        var cols= []
        for(var j=0;j < numdigit+1;j++)
        {   
            if(j ==0){
                cols.push(<Col  key={++count}>
                    &nbsp;
                </Col>)
            }else{
                cols.push(<Col  key={++count}>
                    <AnsBlock className="Block" name="?"/>
                </Col>)
            }
            
        }
        rows.push(<Row  nr="answer"  className="noselect" key={++count}>{cols}</Row>)

        return rows;
    }
    
    render() { 
        return ( <div ref={this.myRef} className='container'>
              <FontAwesomeIcon 
                 onClick={(e) => this.props.mAnim(e)}
            
            icon={faLightbulb} style={{width:"10px"}} />

           {this.buildBlock()}
        </div>);
    }
}
 
export default memo(Sub);