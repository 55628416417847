export function location(view){
    var sRect = view.getBoundingClientRect();
    return {"X":sRect.left,"Y":sRect.top}
    
}

export function locationA(view){

   var point = location(view);

   return [point.X,point.Y];
    
}

export function adjustlocation(loc,delta){
    return {"X":loc.left-delta.X,"Y":loc.yop-delta.Y}
    
}

export function row(view){
    return attribute(view,"row")
}

export function col(view){
    return attribute(view,"col")
}

export function attribute(view,name){
    return parseInt(view.getAttribute(name))
}

export function className(view){
    return view.getAttribute("class")
}

export function planet(view){
    return view.childNodes[0]
}

export function remove(view){
    try{
        view.parentNode.removeChild(view)
    }catch(e){}
}

export function setLocation(view,loc){
    view.style["position"] = "absolute"
    view.style["top"] = loc.Y  +"px";
    view.style["left"] = loc.X +"px";
}

export function setLocationA(view,loc){
    view.style["position"] = "absolute"
    view.style["top"] = loc[1]  +"px";
    view.style["left"] = loc[0] +"px";
}
