import {Component,props} from "react";
import './add.css'

class ABlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name : this.props.name,
    }
  }

  getName = () =>{
    return this.name;
  } 

  handleClick = () => {

  }


  render(){
     var {name} = this.props; 
     return (
        <div name={name} 
          className={"Block Color" + name }>
              {name}
        </div>
    );
  }
}

export default ABlock;